import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";

type Props = {
  getRootProps: () => DropzoneRootProps;
  getInputProps: () => DropzoneInputProps;
  isDragActive: boolean;
};

const ImageDropZone = ({
  getRootProps,
  getInputProps,
  isDragActive,
}: Props) => (
  <div
    {...getRootProps()}
    className={`w-full rounded-lg border border-dashed border-gray-900/25 px-6 py-10 mt-3 text-center ${isDragActive ? "bg-primary-100 text-bgColor border border-primary-100" : ""}`}
  >
    <CloudUploadIcon className="mx-auto mb-4 scale-[1.4] text-gray-300" />
    <input {...getInputProps()} />
    <div>
      <span
        className={`${isDragActive ? "text-bg-Color" : "text-primary-100"}`}
      >
        Browse{" "}
      </span>
      <span>or drag images here</span>
    </div>
  </div>
);

export default ImageDropZone;
