import { MRT_SortingState } from "material-react-table";
import { useEffect, useState } from "react";
import { ColumnSort } from "@tanstack/table-core";

type Props = {
  sortValue?: ColumnSort;
  onSort?: (sortArray?: ColumnSort[]) => void;
};

function useSorting({ sortValue, onSort }: Props) {
  const [sorting, setSorting] = useState<MRT_SortingState>(
    sortValue ? [sortValue] : []
  );

  useEffect(() => {
    if (onSort) {
      onSort(sorting);
    }
  }, [sorting]);

  return { sorting, setSorting };
}

export default useSorting;
