import { Updater } from "@tanstack/table-core";
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_Row,
  MRT_RowData,
  MRT_RowSelectionState,
  MRT_SortingState,
  MRT_TableInstance,
  MRT_TableOptions,
  MRT_GlobalFilterTextField,
  MRT_TablePagination,
  MRT_Localization,
} from "material-react-table";
import { Dispatch, SetStateAction } from "react";
import { ColumnFilter } from "~/utils/tsUtils";
import { ContentCopy } from "@mui/icons-material";
import TableHeader from "./Header";
import { SkeletonProps, TextFieldProps } from "@mui/material";

export type Props<TData extends MRT_RowData> = {
  rowCount?: number;
  searchText?: string | null;
  columns: MRT_ColumnDef<TData>[];
  columnOrder?: string[];
  data: TData[];
  selectedRows?: Record<string, boolean>;
  isSearchable?: boolean;
  isPaginated?: boolean;
  isSortable?: boolean;
  clientSortOnly?: boolean;
  showColumnFilters?: boolean;
  columnFilters?: ColumnFilter[];
  isCompact?: boolean;
  enableRowDragging?: boolean;
  isDraggable?: boolean;
  draggingRow?: MRT_Row<TData> | null;
  localization?: MRT_Localization;
  enableRowSelection?: boolean;
  enableMultiRowSelection?: boolean;
  isLoading?: boolean;
  layoutMode?: MRT_TableOptions<TData>["layoutMode"];
  isMultiSort?: boolean;
  sorting?: MRT_SortingState;
  children?: React.ReactNode;
  pagination?: MRT_PaginationState;
  muiTablePaperProps?: { onDragEnter: () => void };
  setSorting?: Dispatch<SetStateAction<MRT_SortingState>>;
  setColumnFilters?: Dispatch<SetStateAction<ColumnFilter[]>>;
  setPagination?: Dispatch<SetStateAction<MRT_PaginationState>>;
  onDraggingRowChange?: Dispatch<SetStateAction<MRT_Row<TData> | null>>;
  onSearch?: (value: string) => void;
  onDragEnd?: (table: MRT_TableInstance<TData>) => void;
  onRowClick?: (id: string, status: boolean) => void;
  onRowStyle?: (rowData: TData) => string;
  onRowSelectionChange?: (newValue: Updater<MRT_RowSelectionState>) => void;
  renderDetailPanel?: ({ row }: { row: MRT_Row<TData> }) => JSX.Element | null;
  renderRowActions?: (data: TData) => JSX.Element;
};

export const getTableConfig = <TData extends MRT_RowData>({
  columns,
  data,
  isSearchable = false,
  showColumnFilters = false,
  searchText,
  columnOrder,
  pagination,
  isCompact = false,
  columnFilters,
  selectedRows,
  sorting,
  isLoading,
  draggingRow,
  isDraggable = false,
  enableRowDragging = false,
  enableRowSelection = false,
  enableMultiRowSelection = false,
  isPaginated = false,
  rowCount,
  isSortable = false,
  isMultiSort = false,
  clientSortOnly,
  localization,
  layoutMode,
  muiTablePaperProps,
  children,
  setPagination,
  setSorting,
  setColumnFilters,
  onDraggingRowChange,
  onSearch,
  onRowClick,
  onRowStyle,
  onDragEnd,
  onRowSelectionChange,
  renderRowActions,
  renderDetailPanel,
}: Props<TData>): MRT_TableOptions<TData> => {
  const handleRowClick = (row: TData) => {
    const status = selectedRows?.[row?.id];
    onRowClick?.(row?.id, !status);
  };

  const createMuiSkeletonProps = (): SkeletonProps => ({
    animation: "wave",
    height: 200,
  });

  const muiProps = {
    tableBodyRow: ({ row }: { row: MRT_Row<TData> }) => ({
      sx: {
        color: row.getIsSelected() ? "#f45e45" : null,
        cursor: onRowClick ? "pointer" : null,
        fontSize: "18px",
        backgroundColor: onRowStyle ? onRowStyle(row.original) : "transparent",
      },
      onClick: () => handleRowClick(row.original),
    }),
    tableHeadRow: {
      sx: { backgroundColor: "transparent" },
    },
    tableHeadCell: {
      sx: {
        color: "gray",
        textTransform: "uppercase",
        fontSize: "12px",
      },
    },
    tableBottomToolbar: {
      sx: {
        backgroundColor: "transparent",
      },
    },
    rowDragHandle: ({ table }: { table: MRT_TableInstance<TData> }) => ({
      onDragEnd: () => {
        onDragEnd?.(table);
      },
    }),
    searchTextField: (): TextFieldProps => ({
      placeholder: "Search",
      sx: {
        minWidth: "18rem",
      },
      variant: "outlined",
    }),
    detailPanel: ({ row }: { row: MRT_Row<TData> }) => ({
      sx: {
        padding: row.getIsExpanded() ? "16px !important" : "0 16px !important",
      },
    }),
    rowActions: ({ row }: { row: MRT_Row<TData> }) => (
      <div className="flex items-center gap-4 justify-end">
        {renderRowActions?.(row.original)}
      </div>
    ),
    topToolbar: ({ table }: { table: MRT_TableInstance<TData> }) => (
      <TableToolbar
        table={table}
        children={children}
        isPaginated={isPaginated}
      />
    ),
    columnDefOptions: {
      "mrt-row-actions": {
        header: "",
        size: 200,
      },
    },
    expandButtons: ({ row }: { row: MRT_Row<TData> }) => ({
      sx: {
        transform: row.getIsExpanded() ? "rotate(180deg)" : "rotate(-90deg)",
        transition: "transform 0.2s",
      },
    }),
    copyButton: {
      fullWidth: true,
      startIcon: <ContentCopy />,
      sx: { justifyContent: "flex-start" },
    },
    pagination: {
      rowsPerPageOptions: [5, 10, 20, 50],
    },
  };

  return {
    columns,
    data,
    enableFilterMatchHighlighting: false,
    initialState: {
      showGlobalFilter: isSearchable,
      showColumnFilters,
      globalFilter: searchText,
      columnOrder,
    },
    state: {
      pagination,
      density: isCompact ? "compact" : "comfortable",
      columnFilters,
      rowSelection: selectedRows ?? {},
      sorting,
      showLoadingOverlay: isLoading,
      ...(draggingRow ? { draggingRow } : {}),
    },
    enableRowOrdering: isDraggable,
    enableRowDragging,
    muiRowDragHandleProps: muiProps.rowDragHandle,
    ...(onDraggingRowChange ? { onDraggingRowChange } : {}),
    muiSkeletonProps: createMuiSkeletonProps(),
    muiSearchTextFieldProps: muiProps.searchTextField,
    enableRowSelection,
    enableMultiRowSelection,
    getRowId: (row: TData) => row?.id,
    onRowSelectionChange: onRowSelectionChange,
    enablePagination: isPaginated,
    manualPagination: isPaginated ?? false,
    muiPaginationProps: muiProps.pagination,
    onPaginationChange: setPagination,
    rowCount,
    muiCopyButtonProps: muiProps.copyButton,
    enableSorting: isSortable,
    enableMultiSort: isMultiSort,
    isMultiSortEvent: () => isMultiSort,
    manualSorting: isSortable && !clientSortOnly,
    onSortingChange: setSorting,
    manualFiltering: true,
    onGlobalFilterChange: onSearch && onSearch,
    onColumnFiltersChange: setColumnFilters,
    localization,
    layoutMode,
    renderDetailPanel,
    enableExpandAll: !!renderDetailPanel,
    muiDetailPanelProps: muiProps.detailPanel,
    muiExpandButtonProps: muiProps.expandButtons,
    muiTablePaperProps,
    muiTableBodyRowProps: muiProps.tableBodyRow,
    muiTableHeadRowProps: muiProps.tableHeadRow,
    muiTableHeadCellProps: muiProps.tableHeadCell,
    muiBottomToolbarProps: muiProps.tableBottomToolbar,
    enableColumnActions: false,
    enableRowActions: true,
    positionActionsColumn: "last" as "last",
    displayColumnDefOptions: muiProps.columnDefOptions,
    renderRowActions: muiProps.rowActions,
    renderTopToolbar: muiProps.topToolbar,
  };
};

type TableToolbarProps<TData extends MRT_RowData> = {
  children?: React.ReactNode;
  table: MRT_TableInstance<TData>;
  isPaginated?: boolean;
};

const TableToolbar = <TData extends MRT_RowData>({
  children,
  table,
  isPaginated,
}: TableToolbarProps<TData>) => (
  <TableHeader>
    <div className="flex items-center gap-2 pl-[5px]">
      <MRT_GlobalFilterTextField table={table} />
      {children}
    </div>
    {isPaginated && (
      <div>
        <MRT_TablePagination table={table} />
      </div>
    )}
  </TableHeader>
);
