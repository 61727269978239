import {
  RestaurantMenu,
  FileCopy,
  OutdoorGrill,
  Store,
  ShoppingCart,
  Group,
  Face,
  TrendingUp,
  Star,
  Redeem,
  FiberNew,
  Forum,
  DonutLarge,
  RemoveFromQueue,
  Kitchen,
  EmojiNature,
  Straighten,
  FilterList,
  ContactSupport,
  TurnedInNot,
  Settings,
  CalendarToday,
  PowerSettingsNew,
  Fastfood,
  Person,
  Egg,
  Search,
} from "@mui/icons-material";
import { acceptedCmsRoles } from "~/features/Accounts/constants";

export const getNavlinks = (cmsRole?: string) => {
  const isAdmin = cmsRole === "admin";
  return [
    {
      title: "Recipes",
      links: [
        {
          text: "Recipes",
          path: "/recipes",
          icon: <RestaurantMenu />,
        },
        {
          text: "Step Templates",
          path: "/stepTemplates",
          icon: <FileCopy />,
        },
        {
          text: "Authors",
          path: "/authors",
          icon: <Face />,
        },
        {
          text: "Ingredients",
          path: "/ingredients",
          icon: <Egg />,
        },
        {
          text: "Products",
          path: "/products",
          icon: <ShoppingCart />,
        },
        {
          text: "Appliances",
          path: "/kitchenAppliances",
          icon: <OutdoorGrill />,
        },
        {
          text: "Calendar",
          path: "/calendar",
          icon: <CalendarToday />,
        },
        {
          text: "Reports",
          path: "/reports",
          icon: <DonutLarge />,
        },
      ],
    },
    {
      title: "Configuration",
      links: configurationLinks.filter((item) => !item.adminOnly || isAdmin),
    },
    {
      title: "Campaigns",
      links: [
        {
          text: "Campaigns",
          path: "/campaigns",
          icon: <Star />,
        },
        {
          text: "Author Intros",
          path: "/authorIntros",
          icon: <FiberNew />,
        },
        {
          text: "Promocodes",
          path: "/promocodes",
          icon: <Redeem />,
        },
        {
          text: "Ad 2 App",
          path: "/ad2AppCampaigns",
          icon: <TrendingUp />,
        },
        {
          text: "Surveys",
          path: "/surveys",
          icon: <Forum />,
        },
      ],
    },
    {
      title: "Discovery",
      links: [
        {
          text: "Screen",
          path: "/discoveryScreen",
          icon: <RemoveFromQueue />,
        },
        {
          text: "Curated Lists",
          path: "/curatedLists",
          icon: <Kitchen />,
        },
        {
          text: "Automated Lists",
          path: "/automatedLists",
          icon: <Kitchen />,
        },
      ],
    },
    {
      title: "Onboarding",
      links: [
        {
          text: "Ques. Views",
          path: "/questionnaireViews",
          icon: <ContactSupport />,
        },
        {
          text: "Ques. Options",
          path: "/questionnaireOptions",
          icon: <TurnedInNot />,
        },
      ],
    },
    {
      title: "Accounts",
      links:
        cmsRole && acceptedCmsRoles.includes(cmsRole)
          ? [
              {
                text: "Accounts",
                path: "/accounts",
                icon: <Group />,
              },
              {
                text: "My Account",
                path: "/myAccount/me",
                icon: <Person />,
              },
            ]
          : [
              {
                text: "My Account",
                path: "/myAccount/me",
                icon: <Person />,
              },
            ],
    },
    {
      title: "",
      links: [
        {
          text: "Settings",
          path: "/settings",
          icon: <Settings />,
        },
        {
          text: "Logout",
          path: "/logout",
          icon: <PowerSettingsNew />,
        },
      ],
    },
  ];
};

const configurationLinks = [
  {
    text: "Measurements",
    path: "/measurements",
    icon: <Straighten />,
  },
  {
    text: "Retailers",
    path: "/retailers",
    icon: <Store />,
  },
  {
    text: "Nutrition",
    path: "/nutrition",
    icon: <Fastfood />,
  },
  {
    text: "Ingredient Tags",
    path: "/ingredientTags",
    icon: <EmojiNature />,
  },
  {
    text: "Recipe Filters",
    path: "/recipeFilters",
    icon: <FilterList />,
  },
  {
    text: "Client Filters",
    path: "/clientFilters",
    icon: <FilterList />,
  },
  {
    text: "FridgeFinds",
    path: "/fridgeFinds",
    icon: <Search />,
    adminOnly: true,
  },
];
