import { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Option } from "~/types";
import { clsx } from "clsx";
import { AutocompleteGetTagProps } from "@mui/material";

type Props<CustomOption extends Option> = {
  options: CustomOption[];
  onChange: (value: CustomOption[]) => void;
  label?: string;
  className?: string;
  getOptionLabel?: (option: CustomOption) => string;
  disabled?: boolean;
  placeholder?: string;
  defaultIds?: string[];
  loading?: boolean;
  isOptionEqualToValue?: (option: CustomOption, value: CustomOption) => boolean;
  renderTags?: (
    selected: CustomOption[],
    getTagProps: AutocompleteGetTagProps
  ) => JSX.Element[];
};

function SimpleMultiAutoComplete<CustomOption extends Option = Option>({
  disabled = false,
  ...props
}: Props<CustomOption>) {
  const getOptionLabel = (option: CustomOption) => {
    return option.value;
  };
  const [inputValue, setInputValue] = useState("");

  return (
    <Autocomplete
      className={props.className}
      disabled={disabled}
      defaultValue={props.options.filter((opt) =>
        props.defaultIds?.includes(opt.id)
      )}
      multiple={true}
      renderTags={props.renderTags}
      disableCloseOnSelect={true}
      onChange={(_: any, newValue: CustomOption[] | null) => {
        if (newValue) {
          props.onChange(newValue);
        }
      }}
      loading={props.loading}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={props.options}
      getOptionLabel={getOptionLabel}
      getOptionKey={(option) => option.id}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          placeholder={props.placeholder}
        />
      )}
      isOptionEqualToValue={
        props.isOptionEqualToValue ??
        ((option, value) => option.id === value.id)
      }
    />
  );
}

export default SimpleMultiAutoComplete;
