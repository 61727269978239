import { ReactNode } from "react";
import { Drawer as MuiDrawer } from "@mui/material";
import { useWindowDimensions } from "../hooks";

type Props = {
  children: ReactNode;
  toggle: (isOpen: boolean) => void;
  isOpen: boolean;
  styleProp?: { width?: string };
};

const Drawer = ({ children, toggle, isOpen, styleProp = {} }: Props) => {
  const defaultDrawerWidth =
    (useWindowDimensions()?.width ?? 1000) < 900 ? "90vw" : styleProp?.width;

  return (
    <MuiDrawer
      anchor="right"
      open={isOpen}
      PaperProps={{
        sx: {
          width: defaultDrawerWidth,
          maxWidth: defaultDrawerWidth ?? "75vw",
          minWidth: defaultDrawerWidth ?? "40vw",
          height: "100%",
        },
      }}
      onClose={() => toggle(false)}
    >
      <div className="p-5">{children}</div>
    </MuiDrawer>
  );
};

export default Drawer;
