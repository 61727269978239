import { useState } from "react";
import { SecondaryBtn } from ".";
import { ApiResponse, createToastMessage } from "~/server/utils";
import useToastWithState from "~/hooks/useToastWithState";
import { Button } from "@mui/material";
import TranslateIcon from "@mui/icons-material/Translate";

type Props = {
  lokaliseFn: () => Promise<ApiResponse<undefined>>;
};

const LokaliseButton = ({ lokaliseFn }: Props) => {
  const [isFetching, setIsFetching] = useState(false);
  const showToast = useToastWithState();

  const handleLokalise = async () => {
    setIsFetching(true);
    let toastMessage;
    try {
      const res = await lokaliseFn();
      toastMessage = createToastMessage(res);
      showToast(toastMessage);
    } catch (error) {
      showToast({ msg: "Error on Lokalise", status: 400 });
    }

    setIsFetching(false);
  };

  return (
    <Button
      variant="outlined"
      size="small"
      onClick={handleLokalise}
      disabled={isFetching}
      endIcon={<TranslateIcon />}
    >
      {isFetching ? "Fetching..." : "Localise"}
    </Button>
  );
};

export default LokaliseButton;
