import _ from "lodash";
import { diets } from "./constants";
import {
  AccountDetail,
  AccountRow,
  AccRecipeFav,
  OwnedAccount,
  RecipeFav,
  SubHistory,
} from "./types";
import { Option, DeleteInfo } from "~/types";
import {
  handleAddMyRecipeFav,
  handleAddOwnAccRecipe,
  handleAddRecipe,
  handleChangeMyPassword,
  handleChangeOwnedAccPassword,
  handleChangePassword,
} from "./handlers";
import { capitalizeFirstLetter } from "~/utils/tsUtils";

export const getDiets = (dietTag: keyof typeof diets): string => diets[dietTag];

export const getExcludedIngredients = (list: string[]) =>
  list.map((ing) => {
    const splitIng = capitalizeFirstLetter(ing.split(":")[1].replace("_", " "));
    return splitIng;
  });

export const getActiveSubscriptionStatus = (
  subscriptionHistory: SubHistory[]
) => {
  const today = new Date();
  const activeSubscription = subscriptionHistory.find((subscription) => {
    const startDate = new Date(subscription.startDate);
    const endDate = new Date(subscription.endDate);

    return today > startDate && today < endDate;
  });
  return activeSubscription?.status;
};

const getMostRecentlyEndedSubStatus = (subscriptionHistory: SubHistory[]) => {
  if (subscriptionHistory.length === 0) {
    return undefined;
  }

  const mostRecentlyEndedSub = subscriptionHistory
    .filter((sub) => sub.endDate)
    .sort(
      (a, b) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime()
    )[0];

  return mostRecentlyEndedSub?.status;
};

export const getSubStatus = (subscriptionHistory: SubHistory[]) => {
  if (!subscriptionHistory || subscriptionHistory.length === 0) {
    return null;
  }
  const activeStatus = getActiveSubscriptionStatus(subscriptionHistory);
  return activeStatus ?? getMostRecentlyEndedSubStatus(subscriptionHistory);
};

export const createOwnedAccObj = (account: OwnedAccount) => ({
  id: account.id,
  firstName: account.firstName,
  lastName: account.lastName,
  email: account.email,
  status: account.status,
  favCount: account.favCount,
  creationDate: account.creationDate,
  updateDate: account.updateDate,
  cmsRole: account?.cmsRole,
});

export const getFilteredRecipes = (recipes: Option[], favs: AccRecipeFav[]) => {
  const comparator = (recipe: Option, fav: AccRecipeFav) =>
    recipe.id === fav.identifier;
  const updatedRecipes = _.differenceWith(recipes, favs, comparator);
  return updatedRecipes;
};

export const getFormValues = (account: AccountDetail) => ({
  id: account.id,
  firstName: account?.firstName,
  lastName: account?.lastName,
  email: account?.email,
  status: account?.status,
  cmsRole: account?.cmsRole,
  favSpace: account?.favSpace,
  permission: account?.permission?.name ?? "normal",
  hasSubscriptionAccess: account?.hasSubscriptionAccess,
  hasModularizationAccess: account?.hasModularizationAccess,
  ownedAccounts: account?.ownedAccounts.map((account) => account.id),
});

export const getOwnedAccValues = (account: AccountDetail) => ({
  id: account?.id,
  firstName: account?.firstName,
  lastName: account?.lastName,
  email: account?.email,
  status: account?.status,
  cmsRole: account?.cmsRole,
  favSpace: account?.favSpace,
  hasSubscriptionAccess: account?.hasSubscriptionAccess,
  hasModularizationAccess: account?.hasModularizationAccess,
});

export function getDaysLeft(validUntilTimestamp: string) {
  const currentDate = new Date().getTime();
  const validUntilDate = new Date(validUntilTimestamp).getTime();
  const timeDifference = validUntilDate - currentDate;
  const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  return daysLeft;
}

export const getTimestamp = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${day < 10 ? "0" + day : day}/${
    month < 10 ? "0" + month : month
  }/${year}`;
};

export const buildRecipeOptions = (recipes: RecipeFav[]) =>
  recipes.map((recipe) => ({
    id: recipe.id ?? "",
    value: `${recipe.gdocs} - ${recipe.title}`,
  }));

export const getRecipeHandler = (
  isOwnedAccount: boolean,
  myAccount: boolean
) => {
  if (isOwnedAccount) {
    return handleAddOwnAccRecipe;
  }
  if (myAccount) {
    return handleAddMyRecipeFav;
  }
  return handleAddRecipe;
};

export const getPasswordHandler = (
  isOwnedAccount: boolean,
  myAccount: boolean
) => {
  if (isOwnedAccount) {
    return handleChangeOwnedAccPassword;
  }
  if (myAccount) {
    return handleChangeMyPassword;
  }
  return handleChangePassword;
};

export const getDeleteInfo = (acc: AccountRow): DeleteInfo => ({
  deleteArgs: [acc.id],
  name: `${acc.firstName} ${acc.lastName}`,
});

export const getRecipeDeleteInfo = (
  recipe: AccRecipeFav,
  accountId: string
): DeleteInfo => ({
  deleteArgs: [accountId, recipe.identifier],
  name: recipe.title ?? "",
});

export const getMyRecipeDeleteInfo = (recipe: AccRecipeFav): DeleteInfo => ({
  deleteArgs: [recipe.identifier],
  name: recipe.title ?? "",
});
