import { Dispatch, SetStateAction } from "react";
import {
  MaterialReactTable,
  type MRT_RowData,
  type MRT_ColumnDef,
  type MRT_TableOptions,
  useMaterialReactTable,
  MRT_TableInstance,
  MRT_Row,
} from "material-react-table";
import { RowSelectionState, Updater, ColumnSort } from "@tanstack/table-core";
import { ColumnFilter } from "~/utils/tsUtils";
import { getTableConfig } from "./utils";
import usePagination from "./hooks/usePagination";
import useSorting from "./hooks/useSorting";
import useFilterChange from "./hooks/useFilterChange";

interface Props<TData extends MRT_RowData> {
  children?: React.ReactNode;
  columns: MRT_ColumnDef<TData>[];
  data: TData[];
  isCompact?: boolean;
  localization?: any;
  useMemoDependencies?: string[];
  isLoading?: boolean;
  layoutMode?: MRT_TableOptions<TData>["layoutMode"];
  muiTablePaperProps?: { onDragEnter: () => void };
  searchProps?: {
    isSearchable?: boolean;
    searchText?: string | null;
    onSearch?: (value: string) => void;
  };
  sortingProps?: {
    isSortable?: boolean;
    clientSortOnly?: boolean;
    sortValue?: ColumnSort;
    isMultiSort?: boolean;
    onSort?: (sortArray?: ColumnSort[]) => void;
  };
  paginationProps?: {
    isPaginated?: boolean;
    page?: number;
    pageSize?: number;
    rowCount?: number;
    onPageChange?: (page: number, size: number) => void;
  };
  filteringProps?: {
    showColumnFilters?: boolean;
    columnOrder?: string[];
    onFilterChange?: (filterValues: ColumnFilter[]) => void;
  };
  draggingProps?: {
    enableRowDragging?: boolean;
    isDraggable?: boolean;
    draggingRow?: MRT_Row<TData> | null;
    onDragEnd?: (table: MRT_TableInstance<TData>) => void;
    onDraggingRowChange?: Dispatch<SetStateAction<MRT_Row<TData> | null>>;
  };
  rowSelectionProps?: {
    enableRowSelection?: boolean;
    enableMultiRowSelection?: boolean;
    selectedRows?: any;
    onRowClick?: (id: string, status: boolean) => void;
    onRowStyle?: (rowData: TData) => string;
    onRowSelectionChange?: (newValue: Updater<RowSelectionState>) => void;
  };
  renderDetailPanel?: ({ row }: { row: MRT_Row<TData> }) => JSX.Element | null;
  renderRowActions?: (data: TData) => JSX.Element;
}

export default function Table<TData extends MRT_RowData>({
  searchProps,
  sortingProps,
  paginationProps,
  filteringProps,
  draggingProps,
  rowSelectionProps,
  children,
  columns,
  data,
  isCompact = false,
  localization,
  isLoading,
  layoutMode = "semantic",
  muiTablePaperProps,
  renderDetailPanel,
  renderRowActions,
}: Props<TData>) {
  const { isSearchable, searchText, onSearch } = searchProps ?? {};

  const { isSortable, clientSortOnly, sortValue, isMultiSort, onSort } =
    sortingProps ?? {};

  const {
    isPaginated,
    page,
    pageSize = 50,
    rowCount,
    onPageChange,
  } = paginationProps ?? {};

  const { showColumnFilters, columnOrder, onFilterChange } =
    filteringProps ?? {};

  const {
    enableRowDragging,
    isDraggable,
    draggingRow,
    onDragEnd,
    onDraggingRowChange,
  } = draggingProps ?? {};

  const {
    enableRowSelection,
    enableMultiRowSelection,
    selectedRows,
    onRowClick,
    onRowStyle,
    onRowSelectionChange,
  } = rowSelectionProps ?? {};

  const { pagination, setPagination } = usePagination({
    page,
    pageSize,
    onPageChange,
  });

  const { sorting, setSorting } = useSorting({
    sortValue,
    onSort,
  });

  const { columnFilters, setColumnFilters } = useFilterChange({
    onFilterChange,
  });

  const tableConfig = getTableConfig<TData>({
    columns,
    data,
    isSearchable,
    showColumnFilters,
    searchText,
    columnOrder,
    pagination,
    isCompact,
    columnFilters,
    selectedRows,
    sorting,
    isLoading,
    draggingRow,
    isDraggable,
    enableRowDragging,
    enableRowSelection,
    enableMultiRowSelection,
    isPaginated,
    rowCount,
    isSortable,
    isMultiSort,
    clientSortOnly,
    localization,
    layoutMode,
    muiTablePaperProps,
    children,
    setPagination,
    setSorting,
    setColumnFilters,
    onDraggingRowChange,
    onSearch,
    onRowClick,
    onRowStyle,
    onDragEnd,
    onRowSelectionChange,
    renderRowActions,
    renderDetailPanel,
  });

  const table = useMaterialReactTable(tableConfig);

  return (
    <div className="grid col-span-12 zoom">
      <MaterialReactTable table={table} />
    </div>
  );
}
