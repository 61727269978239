import * as Apollo from "@apollo/client";
import TranslateIcon from "@mui/icons-material/Translate";
import { toast } from "sonner";
import { Button } from "@mui/material";

type Props<T, U> = {
  lokaliseFn: Apollo.MutationFunction<T, U>;
  isDisabled: boolean;
};

const LokaliseButtonG = <T extends object, U extends object>({
  lokaliseFn,
  isDisabled,
}: Props<T, U>) => {
  const handleLokalise = async () => {
    try {
      await lokaliseFn();
      toast.success("Lokalise Successful");
    } catch (error) {
      toast.error("Lokalise Failed");
    }
  };

  return (
    <Button
      variant="outlined"
      size="small"
      onClick={handleLokalise}
      disabled={isDisabled}
      endIcon={<TranslateIcon />}
    >
      {isDisabled ? "Fetching..." : "Localise"}
    </Button>
  );
};

export default LokaliseButtonG;
